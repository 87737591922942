<template>
  <!-- 菜单管理 -->
  <div class="card">
    <div class="menu flex jc_s">
      <a href="/menuUser" class="flex jc_s now"><i class="iconfont iconmaijia_wode"></i>用户</a>
      <a href="/menuAuth" class="flex jc_s"><i class="iconfont icontuiguang-1"></i>菜单</a>
    </div>

    <div class="pb16 pt16 flex jc_end ai_c">
      <el-button type="primary" class="ssbtn" style="width: 6.75rem" @click="addBtn">添加用户</el-button>
      <div class="flex jc_end f14 col333 ai_c"></div>
    </div>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="cname" label="姓名" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.ali_name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="cname" label="用户账号" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.username }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="cname" label="用户密码" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.password }}</div>
          </template>
        </el-table-column> -->

        <el-table-column prop="cname" label="状态" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">正常</div>
            <div v-else>禁用</div>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.role == 0">超管</div>
            <div v-else>用户</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="auth" label="默认权限" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.auth == 1">满减卡</div>
            <div v-if="scope.row.auth == 2">兑换卡</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="in20" label="创建时间">
          <template slot-scope="scope">
            <div>{{ scope.row.created_at }}</div>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="240">
          <template slot-scope="scope">
            <el-button class="qxbtn mr10" :class="scope.row.role==0?'bg-grey':''" :disabled="scope.row.role==0?true:false" @click="ck_auth(scope.row)">权限</el-button>
            <el-button class="qxbtn mr10" @click="editBtn(scope.row)">编辑</el-button>
            <el-button class="delbtn" type="danger" @click="delBtn(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加编辑弹窗 -->
    <el-dialog :title="type_title" :show-close="false" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="500px">
      <el-form :model="data_obj" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.ali_name" autocomplete="off" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户账号" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.username" autocomplete="off" placeholder="用户账号"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.password" autocomplete="off" placeholder="用户密码"></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" prop="">
          <el-select filterable clearable class="el_ipt" v-model="data_obj.status" placeholder="状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth" prop="">
          <el-select filterable clearable class="el_ipt" v-model="data_obj.role" placeholder="角色">
            <el-option label="超管" value="0"></el-option>
            <el-option label="用户" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="sureadd('ruleForm')" :loading="loading">提 交</el-button>
      </div>
    </el-dialog>

    <el-dialog id="bianji" width="575px" :visible.sync="authDialog">
      <div class="qxDialogHeader flex-column jc_c">
        <div class="flex jc_b ai_c">
          <span class="f16 colfff">权限</span>
          <i class="iconfont iconguanbi colfff f18" @click="authDialog = false"></i>
        </div>
      </div>
      <div class="qxmid">
        <el-form ref="bjForm">
          <div v-for="(item, index) in auth_list" :key="index" class="pb30">
            <div class="pb15" style="margin-left: -50px">
              <el-checkbox v-model="item.auth" true-label="1" false-label="0">{{ item.menu_name }}</el-checkbox>
            </div>
            <div>
              <el-radio-group v-model="item.auth_status">
                <el-radio  label="0">查看</el-radio>
                <el-radio  label="1">编辑</el-radio>
               </el-radio-group>
            </div>
          </div>

          <div class="qxmidm pb40">
            <el-button type="primary" class="ssbtn ml26" style="width: 5.375rem; margin-right: 5rem" @click="submitAuth('authForm')">确认</el-button>
            <el-button class="cancelbtn" style="width: 5.375rem" @click="resetAuth('authForm')">取消</el-button>
          </div>
        </el-form>
      </div>
      <!--qxmid end-->
    </el-dialog>

    <div class="page pt16">
      <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,

      tableData: [],
      data_obj: {
        ali_name: '',
        username: '',
        password: '',
        status: '',
        role: '',
      },

      formLabelWidth: '80px',
      rules: {
        menu: [{ required: true, message: '菜单名', trigger: 'blur' }],
        index: [{ required: true, message: '节点', trigger: 'blur' }],
        type: [{ required: true, message: '类型', trigger: 'blur' }],
        parentid_list: [{ required: true, message: '上级菜单', trigger: 'blur' }],
      },
      loading: false,

      //   type: 'add',
      type_title: '添加用户',
      dialogFormVisible: false,

      // 权限
      authDialog: false,
      auth_list: [],
      user_id:1
    }
  },
  watch: {},
  computed: {},
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(val)
    },
    ck_auth( row) {
      this.user_id = row.id
      this.getAuth()
      
      this.authDialog = true
    },
    submitAuth() {
      
       
      let list = [], data = {}, uinfo = {},that = this
      uinfo = this.$localStorage.getItem('userInfo')
      console.log('uinfo',uinfo)
      this.auth_list.map((item,index)=>{
        list.push({
          id: item.id,
          auth:item.auth,
          auth_status:item.auth_status
        })
      }) 
      data = {
        admin_id: this.user_id,
        data: list
      }
      this.$api.setAuthAdmin(data).then((res) => {
        console.log('res', res)
        if (res.data.code == 0) {
          that.$message.success('权限已更改')
          setTimeout(function () {
            that.authDialog = false
            that.getData()
          }, 100)
        }
      })
    },
    resetAuth() {
      this.authDialog = false
    },
    addBtn() {
      // this.type = "add";
      this.data_obj = {}
      this.type_title = '添加用户'
      this.dialogFormVisible = true
    },
    editBtn(row) {
      console.log('----',row)
      this.user_id = row.id
      this.data_obj = {}
      this.type_title = '编辑用户'
      this.dialogFormVisible = true
      this.data_obj = {
        ...row,
        password: '',
        role: String(row.role),
        status: String(row.status),
      }
    },
    delBtn() {},
    sureadd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          let data = {},msg=''
          if (this.type_title == '编辑用户') {
            data = {
              ali_name: that.data_obj.ali_name,
              username: that.data_obj.username,
              password: that.data_obj.password,
              status: that.data_obj.status,
              role: that.data_obj.role,
              id: this.data_obj.id,
            }
            msg = '编辑成功'
          } else {
            data = {
              ali_name: that.data_obj.ali_name,
              username: that.data_obj.username,
              password: that.data_obj.password,
              status: that.data_obj.status,
              role: that.data_obj.role,
            }
            msg = '编辑成功'
            if (this.data_obj.password == '') {
              delete this.data_obj.password
            }
          }
          this.$api.setAdmin(data).then((res) => {
            console.log('res', res)
            if (res.data.code == 0) {
              that.$message.success(msg)
              setTimeout(function () {
                that.dialogFormVisible = false
                that.getData()
              }, 100)
            }
          })
        }
      })
    },
    filter(val) {
      let offset = (val - 1) * this.pageSize
      let data = {
        limit: this.pageSize,
        offset: offset,
      }
      return data
    }, //filter
    getData(val = 1) {
      let data = this.filter(val)
      //
      this.$api.getAdmin(data).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.rows
          this.total = res.data.data.count
          console.log(this.total, this.total)
        }
      })
    },
    getAuth() {
      let uinfo = this.$localStorage.getItem('userInfo')
      let data = {
        admin_id: this.user_id
      }
      this.$api.getAuthAdmin(data).then((res) => {
        if (res.data.code == 0) {
          this.auth_list = res.data.data.rows
        }
      })
    },
    handleCancle() {
      this.dialogFormVisible = false
      this.data_obj = {}
    },
  },
  created() {
    this.getData()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.bg-grey{
  background: #ccc !important;
}
</style>